import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import ImageProducto from './../assets/images/producto-01.png'

const SeccionVideo = ( props ) => {
    let producto = props.datos;
    return (
        <div className="seccionVideo" >
            <Container>
                <Row>
                    <Col xs={{ order : 1 ,span : 12 }} sm={{ span: 12 , order: 1}} md={{ span: 12 , order: 1}} lg={{ span: 6 , order: 1}} xl={{ span: 6 , order: 1}} className="textCenter" style={{margin: 0, padding: 0}}>
                        <iframe style={{flex: 1, width: '100%', height: '370px'}} src={producto.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col xs={{ order : 2 ,span : 12 }} sm={{ span: 12 , order: 2}} md={{ span: 6 , order: 1}} lg={{ span: 6 , order: 1}} xl={{ span: 6 , order: 1}} className="infoVideo textCenter" style={{marginTop: 10}} >
                            <h2>¡Esta es una oferta exclusiva!</h2>
                            <h3>RESERVÁ HOY TU <span className="highlight">{ producto.producto }®</span></h3>
                            <br />
                            { producto.tipo == "comprar" || producto.tipo == "hibrido" ?
                                <div className="ca-precio">
                                    <div><span className="badge">-{ producto.descuento }%</span><span className="precio highlight">${ producto.precio }</span> <span className="precioTachado">${ producto.precioTachado }</span></div>
                                    <br />
                                    <div className="buttonComprar">
                                        <a className="btn btn-primary buttonSubmit btnColor" href="#cplForm" >¡ COMPRAR YA ! </a>
                                    </div>
                                </div>
                               
                            : null }
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default SeccionVideo;