import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Seccion1 from './components/Seccion1';
import Seccion2 from './components/Seccion2';
import SeccionCards from './components/SeccionCards';
import Seccion4 from './components/Seccion4';
import Footer from './components/Footer';
import SeccionVideo from './components/SeccionVideo';
import './App.css'

let json = {
  SKU: 835,
  PLU: 6254,
  promo: '',
  producto: 'AB Crunch',
  tipo: 'hibrido', // puede ser 'comprar' o 'hibrido' (form+comprar)
  precio: '26.760',
  precioTachado: '33.625',
  descuento: '21',
  tituloForm : 'Registrate HOY y llévate tus Eternity Copper Cuchillos® a un precio especial El corte perfecto de un lujoso cuchillo de cobre.',
  tituloComprar : 'Compra tu Producto',
  comprarLink : '',
  utmDescuento : '',
  cover: 'images/abcrunch/1-FondoTexto.png',
  seccion2cover : 'images/abcrunch/3-Fondo.png',
  imagenProducto: 'images/abcrunch/5-AbCrunch-img.png',
  colorDestacado: '#009db1',
  tituloHeader: '',
  tituloSeccion3: 'UN GIMNASIO',
  tituloSeccion3Highlight: 'EN CASA LAS 24HS.',
  listaSeccion3: [
    {icon: 'images/abcrunch/3-item-rojo.png', titulo1: '', texto:'Trabajá múltiples partes del cuerpo como abdomen, piernas, muslos, glúteos y caderas.'},  
    {icon: 'images/abcrunch/3-item-rojo.png', titulo1: '', texto:'Desde una sorprendente rutina de hombros, brazos, pectorales, cintura y espalda hasta un divertido entrenamiento cardiovascular para mejorar la circulación y fortalecer el corazón.'},      
  ],
  tituloDescripcion: 'UN GIMNASIO EN CASA LAS 24HS.',
  parrafoDescripcion1: 'ON LOS CUCHILLOS DE ETERNITY COPPER REVESTIMIENTO DE COBRE, PODRÁS CORTAR, REBANAR Y PICAR, MUCHO MÁS FÁCIL; OBTENIENDO CORTES RÁPIDOS, PRECISOS Y SIN ESFUERZO EN CARNES, VERDURAS, FRUTAS, PANES Y MUCHOS MÁS ALIMENTOS',
  parrafoDescripcion2: 'Nada mejor que tener un buen cuchillo a la hora de cocinar. Con este increíble set de 3 cuchillos antiadherentes, podrás cortar los alimentos que desees de una forma más simple y rápida, sin que nada se le quede pegado. Ideales para carnes rojas, blancas, panes, frutas, verduras, embutidos, ¡y hasta para deshuesar las aves!',
  video: 'https://www.youtube.com/embed/c0TueLstP0s',
  tituloCards: '¡UN EQUIPO',
  tituloCardsHighlight: 'PARA TODO EL CUERPO!',
  listaCards: [
    {icon: 'images/abcrunch/4-icono-muscle.png', titulo1: 'ENTRENAMIENTO',titulo2: 'COMPLETO', texto:'Estimula el metabolismo y acelera la quema de grasas, mientras que mejora la figura al fortalecer los músculos comprometidos durante el entrenamiento.'},
    {icon: 'images/abcrunch/4-icono-home.png', titulo1: 'EJERCITATE', titulo2: 'EN TU CASA', texto:'¡Ab Crunch te motiva a alcanzar tus objetivos! Podrás armar rutinas de ejercicios y lograr un cuerpo ágil y saludable.'},
    {icon: 'images/abcrunch/4-icono-time.png', titulo1: 'HACELO', titulo2: 'SUPER FÁCIL', texto:'Diseñado y supervisado para conseguir el estado físico que deseás en solo 8 minutos de entrenamiento diario. Te resultará tan fácil que adquirirás el hábito sin esfuerzo.'},
  ],
  tituloLista: 'FORTALECÉ TUS MÚSCULOS',
  tituloListaHighlight: 'EN LA COMODIDAD DE TU CASA',
  lista1: [
    {titulo: '21 RUTINAS', testo:'Su guía de 21 ejercicios se convertirá en tu entrenador diario las 24 horas.'},
    {titulo: '3 INTENSIDADES', testo:'Cuenta con tres niveles de resistencia regulables a cada necesidad.'},
    {titulo: 'LLEVALO CON VOS', testo:'Podés transportarlo a tu lugar favorito de la casa, oficina y hasta al aire libre.'},
    {titulo: 'AHORRA DINERO', testo:'Este revolucionario sistema te permitirá acomodar la rutina a tu vida diaria, ahorrando tiempo y dinero.'}
  ],
  fondoSeccion: './assets/images/EternityCopper-Portada-Desktop-v2.png',
  textoFooterCTA: 'APROVECHA ESTA PROMOCION AHORA Y LLEVATE DE REGALO UN NICER DICER QUICK',
  botonComprar: 'https://www.tevecompras.com/checkout/cart/add?sc=1&sku=835&qty=1&seller=1&utm_source=e-mkt&utm_medium=cpa&utm_id=af_prospectone'
}


const App = () => {
  return (
    <div className="App">
      <Seccion1 datos={json} /> 
      <SeccionVideo datos={json} /> 
      <Seccion2 datos={json}/>
      <SeccionCards datos={json}/>
      <Seccion4 datos={json}/>
      <Footer />
    </div>
  );
}


export default App;
