import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ImageProducto from './../assets/images/producto-01.png'
import Card from 'react-bootstrap/Card';
import Icon1 from './../assets/images/Icon1.png'

const SeccionCards = ( props ) => {
    let producto = props.datos;
    return (
        <div className="seccion2" >
            <Container>
                <Row className="textCenter">
                    <Col md={12}>
                    <h3>{ producto.tituloCards } <span className="highlight">{ producto.tituloCardsHighlight }</span>  </h3>
                    </Col>
                </Row>                
                <Row className="textCenter">
                    <Col md={4} xs={12}>
                        <Card className="textCenter">
                            <Card.Title style={{ paddingTop: '30px' }}>{producto.listaCards[0].titulo1 } <span className="highlight">{producto.listaCards[0].titulo2 }</span></Card.Title>
                            <Card.Title><img className="imgIcon" src={ producto.listaCards[0].icon } alt="Logo" /></Card.Title>
                            <Card.Body>{producto.listaCards[0].texto }</Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} xs={12}>                        
                        <Card>
                        <Card.Title style={{ paddingTop: '30px' }}>{producto.listaCards[1].titulo1 } <span className="highlight">{producto.listaCards[1].titulo2 }</span></Card.Title>
                            <Card.Title><img className="imgIcon" src={producto.listaCards[1].icon } alt="Logo" /></Card.Title>
                            <Card.Body>{producto.listaCards[1].texto }</Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} xs={12}>
                        <Card>
                        <Card.Title style={{ paddingTop: '30px' }}>{producto.listaCards[2].titulo1 } <span className="highlight">{producto.listaCards[2].titulo2 }</span></Card.Title>
                            <Card.Title><img className="imgIcon" src={producto.listaCards[2].icon } alt="Logo" /></Card.Title>
                            <Card.Body>{producto.listaCards[2].texto }</Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SeccionCards;