import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ImageProducto from './../assets/images/producto-01.png'

const Seccion4 = ( props ) => {
    let producto = props.datos;
    return (
        <div className="seccion4" >
            <br /><br />
            <Container>
                <Row className="textCenter">
                    <Col md={12}>
                        <h3 style={{ fontWeight: 100, lineHeight: '5px'}}>{ producto.tituloLista } </h3>
                        <h3 className="highlight">{ producto.tituloListaHighlight } </h3>
                    </Col>
                </Row>
                <Row className="infoProducto">
                    <Col md={6} className="textCenter">
                        <img className="imgProducto" src={ producto.imagenProducto } alt={producto.producto} />
                    </Col>
                    <Col md={6}>
                        <ul className="check">
                            { producto.lista1.map( (item) => (
                                <li><span className="highlight">{ item.titulo }</span>
                                    <br/> 
                                    { item.testo }
                                </li>
                            ))}
                        </ul>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Seccion4;