import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ImageProducto from './../assets/images/producto-01.png'

const Seccion2 = ( props ) => {
    let producto = props.datos;
    return (
        <div className="seccion2" style={{ backgroundImage: `url(${producto.seccion2cover})`}} >
            <Container>
                <Row>
                    <Col xs={{ order : 1 ,span : 12 }} sm={{ span: 0 , order: 1}} md={{ span: 0 , order: 1}} lg={{ span: 5 , order: 1}} className="textCenter">
                        
                    </Col>
                    <Col xs={{ order : 2 ,span : 12 }} sm={{ span: 12 , order: 2}} md={{ span: 12 , order: 1}} lg={{ span: 7 , order: 1}}>
                        <div className="infoAccion2 textCenter">
                            <div className="textCenter">
                                <h4>{producto.tituloSeccion3} <span className="highlight">{producto.tituloSeccion3Highlight}</span></h4>

                            <br />
                                <div className="ullista">
                                    <ul className="check">
                                        { producto.listaSeccion3.map( (item) => (
                                            <li>{ item.texto } </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="textCenter">
                                { producto.tipo == "comprar" || producto.tipo == "hibrido" ?
                                    <div className="ca-precio">
                                    
                                        {/* <div className="precioTachado">${ producto.precioTachado }</div> */}
                                        {/* <div className="precio">${ producto.precio } </div> */}
                                        <div className="buttonComprar">
                                            <a className="btn btn-primary buttonSubmit btnColor" href="#cplForm" >¡ COMPRAR YA ! </a>
                                        </div>
                                    </div>
                                
                                : null }
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Seccion2;