import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Footer = ( props ) => {
    let producto = props.datos;
    return (
        <div className="footer">
            <Container className="textCenter">
                <Row>
                <Col md={12}>
                    <img src="https://www.tevecompras.com/arquivos/TVClogoblanco.png" height="40" />
                    {/* <p><a href="#">Política de Privacidad</a> | <a href="#">Contacto</a></p> */}
                </Col>

                    
                </Row>
            </Container>
        </div>

    )
}

export default Footer;