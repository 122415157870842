import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Seccion1 = ( props ) => {
    let producto = props.datos;
    let actionURL = `/submit/${producto.promo}`
    
    function useQuery() {
        return new URLSearchParams(window.location.search);
    }
    
    let transaction_id = useQuery().get('aff_sub');

    return (
        <div className="seccion1" style={{ backgroundImage: `url(${producto.cover})`}} >
            <Container>
                <Row>
                    <Col xs={{ order : 2 ,span : 12 }} sm={{ span: 0 , order: 1}} md={{ span: 2 , order: 1}} lg={{ span: 6 , order: 1}} xl={{ span: 7 , order: 1}} className="textCenter">
                        {/* <h3>{ producto.tituloHeader } </h3> */}
                        {/* <h1 class="hidden-xs">{ producto.producto } </h1> */}
                        {/* <iframe style={{flex: 1, width: '100%', height: '400px'}} src={producto.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        {/* <p style={{display: 'none'}}>Cuenta con tres nuevas y únicas tecnologías desarrolladas por Sognare®, la marca en la que millones de mexicanos confían su sueño.</p> */}
                    </Col>
                  
                    <Col xs={{ order : 1 , span: 12 }} sm={{ span: 12 , order: 2}} md={{ span: 8 , order: 2}} lg={{ span: 6 , order: 2}} xl={{ span: 4 , order: 1}} className="textCenter">
                        <div class="form" id="cplForm">
                            <div class="form-head">
                                Registrate <span>HOY</span> y llévate tus<br /><span>{ producto.producto }®</span><br /> a un precio especial.
                            </div>
                            <div class="form-content">
                            { producto.tipo == "form" || producto.tipo == "hibrido" ?
                                <form action={actionURL} method="POST" name="form_200504394100843" id="200504394100843" role="form" data-toggle="validator" data-disable="false" data-focus="false" >
                                    <div class="form-group has-error has-danger">
                                        <input type="text" class="form-control userInput" id="input_3" name="q3_nombre" placeholder="Tu nombre completo" data-error="Escribre tu nombre completo" required />
                                        <div class="help-block with-errors" style={{ display : 'none'}}><ul class="list-unstyled"><li>Escribre tu nombre completo</li></ul></div>
                                    </div>
                                    <div class="form-group has-error has-danger">
                                        <input type="email" class="form-control userInput" id="input_4" name="q4_email" placeholder="Tu correo electrónico" data-error="Escriba un E-mail correcto" required />
                                        <div class="help-block with-errors" style={{ display : 'none'}}><ul class="list-unstyled"><li>Escriba un E-mail correcto</li></ul></div>
                                    </div>
                                    <div class="form-group">
                                        <input type="tel" pattern=".{10,14}" data-minlength="11" maxlength="14" class="form-control" id="input_5" name="q5_celular" placeholder="Tu celular ej: 1150307070" data-error="Escriba un numero correcto ej: 1150307070" required />
                                        <div class="help-block with-errors" style={{ display : 'none'}}></div>
                                    </div>

                                    <input type="hidden" id="promo_code" name="simple_spc" value={producto.promo} />
                                    <input type="hidden" id="sku_code" name="simple_sku" value={producto.PLU} />
                                    <input type="hidden" id="sku_name" name="simple_skuname" value={producto.producto} />
                                    <input type="hidden" id="tid" name="tid" value={transaction_id} />
                                
                                    <Button className="buttonSubmit btnColor" type="submit" variant="primary">QUIERO QUE ME CONTACTEN</Button>
                                </form> 
                            : null }
                             {/* { producto.tipo == "comprar" || producto.tipo == "hibrido" ?
                                <div>
                                  
                                    <div className="precioTachado">${ producto.precioTachado }</div>
                                    <div className="precio">${ producto.precio } </div>
                                    <div className="buttonComprar">
                                        <Button className="buttonSubmit btnColor" type="submit" variant="primary">Comprar</Button>
                                    </div>
                                </div>
                               
                            : null } */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
      
    )
}

export default Seccion1;